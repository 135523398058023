import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('contactList')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary mr-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.dialog.addContact = true;
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.addContact')) + "\n    ")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.importContact
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.import')) + "\n    ")])], 1), _vm._v(" "), _c('ContentArea', [_c(VRow, {
    staticClass: "mb-6"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('Input', {
    attrs: {
      "type": "autocomplete",
      "multiple": "",
      "clearable": false,
      "items": _vm.tags,
      "label": _vm.$t('tagName'),
      "placeholder": _vm.$t('placeholder.select')
    },
    scopedSlots: _vm._u([_vm.loading.tag ? {
      key: "append",
      fn: function fn() {
        return [_c(VProgressCircular, {
          attrs: {
            "size": "24",
            "color": "primary",
            "indeterminate": ""
          }
        })];
      },
      proxy: true
    } : null, {
      key: "selection",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
          item = _ref.item,
          parent = _ref.parent,
          selected = _ref.selected;
        return [_c(VChip, _vm._b({
          staticClass: "ma-.5",
          attrs: {
            "input-value": selected,
            "outlined": "",
            "small": "",
            "close": "",
            "close-icon": "mdi-close"
          },
          on: {
            "click:close": function clickClose($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_c('span', {
          directives: [{
            name: "ellipsis",
            rawName: "v-ellipsis"
          }],
          staticStyle: {
            "max-width": "160px"
          }
        }, [_vm._v("\n                " + _vm._s(item) + "\n              ")])])];
      }
    }], null, true),
    model: {
      value: _vm.filterOptions.selectTag,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "selectTag", $$v);
      },
      expression: "filterOptions.selectTag"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('Input', {
    attrs: {
      "type": "autocomplete",
      "multiple": "",
      "items": _vm.currentTeam.users,
      "item-text": "name",
      "item-value": "email",
      "label": _vm.$t('owner'),
      "placeholder": _vm.$t('placeholder.select')
    },
    scopedSlots: _vm._u([_vm.loading.teamMember ? {
      key: "append",
      fn: function fn() {
        return [_c(VProgressCircular, {
          attrs: {
            "size": "24",
            "color": "primary",
            "indeterminate": ""
          }
        })];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.filterOptions.selectOwner,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "selectOwner", $$v);
      },
      expression: "filterOptions.selectOwner"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('Input', {
    attrs: {
      "label": _vm.$t('keyword'),
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('placeholder.keyword.contacts')
    },
    model: {
      value: _vm.filterOptions.keywordText,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "keywordText", $$v);
      },
      expression: "filterOptions.keywordText"
    }
  })], 1)], 1), _vm._v(" "), _c('CustomizableHeaderTable', {
    attrs: {
      "sort-desc": ['created_at'],
      "headers": [{
        text: '氏名',
        value: 'name',
        getter: _vm.$fullName,
        hideable: false
      }, {
        text: '敬称',
        value: 'title',
        empty: _vm.$t('notSet')
      }, {
        text: '会社名',
        value: 'account.name',
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: 'Webサイト',
        value: 'account.site_url',
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: '部署',
        value: 'department',
        empty: _vm.$t('notSet')
      }, {
        text: '役職',
        value: 'job_title',
        empty: _vm.$t('notSet')
      }, {
        text: 'メールアドレス',
        value: 'email',
        empty: _vm.$t('notSet')
      }, {
        text: '電話番号',
        value: 'tel',
        empty: _vm.$t('notSet')
      }, {
        text: 'CRM',
        value: 'crm',
        empty: _vm.$t('notLink'),
        ellipsis: false
      }, {
        text: '住所',
        value: 'shipping_addresses',
        ellipsis: false,
        empty: _vm.$t('notReg')
      }, {
        text: '会社住所',
        value: 'account.shipping_addresses',
        ellipsis: false,
        empty: _vm.$t('notReg')
      }, {
        text: '送付数',
        value: 'order_count',
        align: 'center',
        transform: function transform(val) {
          return _vm.$t('case', [val.toLocaleString()]);
        }
      },
      // TODO APIの対応が必要 { text: 'コスト', value: 'cost', align: 'center', transform: $price },
      {
        text: 'キャンペーン数',
        value: 'campaign_count',
        align: 'center',
        transform: function transform(val) {
          return _vm.$t('case', [val.toLocaleString()]);
        }
      }, {
        text: '最終送付日',
        value: 'last_sent',
        transform: _vm.$date,
        empty: _vm.$t('none')
      }, {
        text: '送付可否',
        value: 'delivery_block',
        align: 'center'
      }, {
        text: '所有者(担当者)',
        value: 'owner.name',
        empty: _vm.$t('notSet')
      }, {
        text: 'コンタクト作成日',
        value: 'created_at',
        transform: _vm.$date
      }, {
        text: 'ノート',
        value: 'note',
        align: 'center'
      }, {
        text: 'タグ',
        value: 'tags',
        empty: _vm.$t('notSet')
      }, _vm.$config.public.debug ? {
        text: '削除(デバッグ)',
        value: 'remove',
        sortable: false,
        align: 'center'
      } : null].filter(function (item) {
        return item != null;
      }),
      "default-headers": ['氏名', '会社名', '部署', '役職', '送付数', 'キャンペーン数', '最終送付日', '送付可否', '所有者(担当者)', 'コンタクト作成日', 'ノート'],
      "items": _vm.datatable_items,
      "item-key": "id",
      "loading": _vm.loading.table,
      "options": _vm.tableOptions,
      "server-items-length": _vm.totalItems,
      "disable-sort": true
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "click:row": function clickRow(item, _, event) {
        return _vm.onClickItem(item, event);
      },
      "auxclick:row": function auxclickRow(event, row) {
        return _vm.onClickItem(row.item, event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.account.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.account ? _c('LinkText', {
          attrs: {
            "text": item.account.name,
            "to": "/accounts/".concat(item.account.id)
          }
        }) : item.crm_company_name ? _c('span', [_vm._v("\n          " + _vm._s(item.crm_company_name) + "\n        ")]) : _vm._e()];
      }
    }, {
      key: "item.account.site_url",
      fn: function fn(_ref3) {
        var _item$account;
        var item = _ref3.item;
        return [(_item$account = item.account) !== null && _item$account !== void 0 && _item$account.site_url ? _c('LinkText', {
          attrs: {
            "text": item.account.site_url,
            "to": item.account.site_url
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.shipping_addresses",
      fn: function fn(_ref4) {
        var _item$shipping_addres;
        var item = _ref4.item;
        return [(_item$shipping_addres = item.shipping_addresses) !== null && _item$shipping_addres !== void 0 && _item$shipping_addres.length ? _c('FloatingTooltip', {
          attrs: {
            "top": "",
            "hoverable": "",
            "max-width": "400px",
            "open-delay": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var _ref6, _item$shipping_addres2, _item$shipping_addres3;
              var on = _ref5.on,
                attrs = _ref5.attrs;
              return [_c('div', _vm._g(_vm._b({
                staticClass: "d-flex"
              }, 'div', attrs, false), on), [_c('AddressLabel', {
                staticStyle: {
                  "pointer-events": "none"
                },
                attrs: {
                  "small": "",
                  "detail": "",
                  "rows": "",
                  "max-width": "200",
                  "alert": false,
                  "address": (_ref6 = (_item$shipping_addres2 = item.shipping_addresses.find(function (it) {
                    var _item$primary_shippin;
                    return it.id === ((_item$primary_shippin = item.primary_shipping_address) === null || _item$primary_shippin === void 0 ? void 0 : _item$primary_shippin.id);
                  })) !== null && _item$shipping_addres2 !== void 0 ? _item$shipping_addres2 : item.shipping_addresses[0]) !== null && _ref6 !== void 0 ? _ref6 : {}
                },
                scopedSlots: _vm._u([((_item$shipping_addres3 = item.shipping_addresses) === null || _item$shipping_addres3 === void 0 ? void 0 : _item$shipping_addres3.length) > 1 ? {
                  key: "label.prepend",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "ml-1 text-body-2 secondary-text--text"
                    }, [_vm._v("\n                    " + _vm._s('+' + _vm.$t('case', [item.shipping_addresses.length - 1])) + "\n                  ")])];
                  },
                  proxy: true
                } : null], null, true)
              })], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _vm._l(item.shipping_addresses, function (address, index) {
          return _c('AddressLabel', {
            key: index,
            class: {
              'mt-1': index > 0
            },
            attrs: {
              "detail": "",
              "rows": "",
              "small": "",
              "address": address,
              "color": "white"
            }
          });
        })], 2) : _vm._e()];
      }
    }, {
      key: "item.account.shipping_addresses",
      fn: function fn(_ref7) {
        var _item$account2, _item$account2$shippi;
        var item = _ref7.item;
        return [(_item$account2 = item.account) !== null && _item$account2 !== void 0 && (_item$account2$shippi = _item$account2.shipping_addresses) !== null && _item$account2$shippi !== void 0 && _item$account2$shippi.length ? _c('FloatingTooltip', {
          attrs: {
            "top": "",
            "hoverable": "",
            "max-width": "400px",
            "open-delay": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var _ref9, _item$account$shippin, _item$account$shippin2;
              var on = _ref8.on,
                attrs = _ref8.attrs;
              return [_c('div', _vm._g(_vm._b({
                staticClass: "d-flex"
              }, 'div', attrs, false), on), [_c('AddressLabel', {
                staticStyle: {
                  "pointer-events": "none"
                },
                attrs: {
                  "small": "",
                  "detail": "",
                  "rows": "",
                  "max-width": "200",
                  "alert": false,
                  "address": (_ref9 = (_item$account$shippin = item.account.shipping_addresses.find(function (it) {
                    var _item$primary_shippin2;
                    return it.id === ((_item$primary_shippin2 = item.primary_shipping_address) === null || _item$primary_shippin2 === void 0 ? void 0 : _item$primary_shippin2.id);
                  })) !== null && _item$account$shippin !== void 0 ? _item$account$shippin : item.account.shipping_addresses[0]) !== null && _ref9 !== void 0 ? _ref9 : {}
                },
                scopedSlots: _vm._u([((_item$account$shippin2 = item.account.shipping_addresses) === null || _item$account$shippin2 === void 0 ? void 0 : _item$account$shippin2.length) > 1 ? {
                  key: "label.prepend",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "tw-ml-1 tw-text-body-2 tw-text-secondary"
                    }, [_vm._v("\n                    " + _vm._s('+' + _vm.$t('case', [item.account.shipping_addresses.length - 1])) + "\n                  ")])];
                  },
                  proxy: true
                } : null], null, true)
              })], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _vm._l(item.account.shipping_addresses, function (address, index) {
          return _c('AddressLabel', {
            key: index,
            class: {
              'mt-1': index > 0
            },
            attrs: {
              "detail": "",
              "rows": "",
              "small": "",
              "address": address,
              "color": "white"
            }
          });
        })], 2) : _vm._e()];
      }
    }, {
      key: "item.crm",
      fn: function fn(_ref10) {
        var _vm$$datetime;
        var item = _ref10.item;
        return [item !== null && item !== void 0 && item.crm_id ? [item !== null && item !== void 0 && item.get_crm_display ? _c('LinkText', {
          attrs: {
            "text": item === null || item === void 0 ? void 0 : item.get_crm_display,
            "to": item === null || item === void 0 ? void 0 : item.crm_url
          }
        }) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "text-caption secondary-text--text"
        }, [_vm._v("\n            " + _vm._s(_vm.$t('lastSyncAt', [(_vm$$datetime = _vm.$datetime(item.last_synced_at)) !== null && _vm$$datetime !== void 0 ? _vm$$datetime : _vm.$t('notSync')])) + "\n          ")])] : _vm._e()];
      }
    }, {
      key: "item.delivery_block",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("\n          " + _vm._s(item.delivery_block ? 'mdi-close' : 'mdi-circle-outline') + "\n        ")])];
      }
    }, {
      key: "item.note",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item !== null && item !== void 0 && item.description ? _c(VMenu, {
          attrs: {
            "open-on-hover": "",
            "offset-y": "",
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref13) {
              var on = _ref13.on,
                attrs = _ref13.attrs;
              return [_c(VIcon, _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-note-outline")])];
            }
          }], null, true)
        }, [_vm._v(" "), _c(VCard, [_c(VCardText, [_c('pre', {
          domProps: {
            "textContent": _vm._s(item === null || item === void 0 ? void 0 : item.description)
          }
        })])], 1)], 1) : _vm._e()];
      }
    }, {
      key: "item.tags",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return _vm._l(item.tags, function (tag) {
          return _c(VChip, {
            key: tag,
            staticClass: "tw-mr-2",
            attrs: {
              "outlined": "",
              "small": ""
            }
          }, [_c('span', {
            directives: [{
              name: "ellipsis",
              rawName: "v-ellipsis"
            }],
            staticClass: "tw-max-w-[160px]"
          }, [_vm._v("\n            " + _vm._s(tag) + "\n          ")])]);
        });
      }
    }, {
      key: "item.remove",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('TextButton', {
          attrs: {
            "color": "error",
            "value": _vm.$t('action.delete')
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.removeContact(item);
            }
          }
        })];
      }
    }])
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('TextButton', {
    attrs: {
      "value": _vm.$t('contacts.download'),
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.downloadContacts();
      }
    }
  })], 1), _vm._v(" "), _c('EditContactDialog', {
    attrs: {
      "show": _vm.dialog.addContact
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "addContact", $event);
      },
      "create": function create($event) {
        return _vm.$router.push("/contact/".concat($event.id));
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };