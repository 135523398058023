/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ImportContactDialog.vue?vue&type=template&id=7087ab5a"
import script from "./ImportContactDialog.vue?vue&type=script&lang=ts&setup=true"
export * from "./ImportContactDialog.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/code/src/components/button/IconButton.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,TwRadio: require('/code/src/components/input/TwRadio.vue').default,TagSelect: require('/code/src/components/widgets/TagSelect.vue').default,SimpleDialog: require('/code/src/components/dialog/SimpleDialog.vue').default})
