import { convert, codeToString, type Encoding } from 'encoding-japanese'

export async function loadFile(file: File): Promise<string> {
  const buffer = await file.arrayBuffer()
  const utf8Array = new Uint8Array(buffer)

  const encodes = [
    'AUTO',
    'UTF8',
    'UNICODE',
    'SJIS',
    'EUCJP',
    'JIS',
    'UTF32',
    'UTF16',
    'UTF16BE',
    'UTF16LE',
    'ASCII',
    'BINARY',
  ] as Encoding[]

  for (const encoding of encodes) {
    const encode = convert(utf8Array, 'UNICODE', encoding)
    const result = codeToString(encode).replace(/(\r?\n)+/g, '\n')

    const regexKana = /[ぁ-んァ-ヶ]/
    // エンコードが正しければ、１行目にひらがなかカタカナがかならず含まれるはず...
    if (regexKana.test(result.split('\n')[0])) {
      // 制御文字 (タブ (\t \x09)、改行 (\n \x0A)、復帰 (\r \x0D)を除く)
      // eslint-disable-next-line no-control-regex
      const regexControlChar = /[\x00-\x08\x0B\x0C\x0E-\x1F\x7F-\x9F]/g
      return result.replaceAll(regexControlChar, '')
    } else {
      continue
    }
  }
  throw new Error('Invalid format')
}

function parseCsv(src: string): Array<Array<string>> {
  const tmp: Array<string> = []
  src = src.replace(/("[^"]*")+/g, (match) => {
    tmp.push(match.slice(1, -1).replace(/""/g, '"'))
    return '[TMP]'
  })
  return src
    .replace(/(\r?\n)+/g, '\n')
    .trimEnd()
    .split('\n')
    .map((row) => {
      return row.split(',').map((val) => (val === '[TMP]' ? tmp.shift()! : val))
    })
}

export async function loadCsv(file: File): Promise<Array<Array<string>>> {
  const text = await loadFile(file)
  const lines = parseCsv(text)
  return lines
}

/* eslint-disable prettier/prettier */
export const CSV_OBJECT_TO_CONTACT_MAP: {
  [key: string]: string | undefined
} = {
  'コンタクト: 姓': 'last_name',
  'コンタクト: 名': 'first_name',
  'コンタクト: 部署': 'department',
  'コンタクト: 役職': 'job_title',
  /* 下位互換 */ 姓: 'last_name',
  /* 下位互換 */ 名: 'first_name',
  /* 下位互換 */ 部署: 'department',
  /* 下位互換 */ 役職: 'job_title',
  'コンタクト: 敬称(未入力の場合は「様」が自動で入ります)': 'title',

  'コンタクト: 郵便番号': 'shipping_addresses[0].postcode',
  'コンタクト: 都道府県': 'shipping_addresses[0].pref',
  'コンタクト: 市区町村': 'shipping_addresses[0].city',
  'コンタクト: 丁目番地号': 'shipping_addresses[0].street1',
  'コンタクト: マンション名など': 'shipping_addresses[0].street2',
  /* 下位互換 */ 郵便番号1: 'shipping_addresses[0].postcode',
  /* 下位互換 */ 都道府県1: 'shipping_addresses[0].pref',
  /* 下位互換 */ 市区町村1: 'shipping_addresses[0].city',
  /* 下位互換 */ 丁目番地1: 'shipping_addresses[0].street1',
  /* 下位互換 */ 丁目番地号1: 'shipping_addresses[0].street1',
  /* 下位互換 */ マンション名など1: 'shipping_addresses[0].street2',

  'コンタクト: crm': 'crm',
  'コンタクト: crm_id': 'crm_id',
  /* 下位互換 */ crm: 'crm',
  /* 下位互換 */ crm_id: 'crm_id',
  /* 下位互換 */ crm_url: 'crm_url',

  'コンタクト: 電話番号': 'tel',
  'コンタクト: Emailアドレス': 'email',
  /* 下位互換 */ 電話番号: 'tel',
  /* 下位互換 */ email: 'email',

  会社名: 'account.name',
  '会社: ウェブサイトURL': 'account.site_url',
  /* 下位互換 */ 会社: 'account.name',
  /* 下位互換 */ '会社: website': 'account.site_url',
  /* 下位互換 */ '会社：website': 'account.site_url',

  '会社: 郵便番号': 'account.shipping_addresses[0].postcode',
  '会社: 都道府県': 'account.shipping_addresses[0].pref',
  '会社: 市区町村': 'account.shipping_addresses[0].city',
  '会社: 丁目番地号': 'account.shipping_addresses[0].street1',
  '会社: マンション名など': 'account.shipping_addresses[0].street2',

  /* 下位互換 */ '会社：郵便番号': 'account.shipping_addresses[0].postcode',
  /* 下位互換 */ '会社：都道府県': 'account.shipping_addresses[0].pref',
  /* 下位互換 */ '会社：市区町村': 'account.shipping_addresses[0].city',
  /* 下位互換 */ '会社: 丁目番地': 'account.shipping_addresses[0].street1',
  /* 下位互換 */ '会社：丁目番地号': 'account.shipping_addresses[0].street1',
  /* 下位互換 */ '会社：マンション名など': 'account.shipping_addresses[0].street2',

  '会社: crm': 'account.crm',
  '会社: crm_id': 'account.crm_id',

  /* 下位互換 */ 追加日付名1: 'additional_date[0].label',
  /* 下位互換 */ 追加日付1: 'additional_date[0].date',
  /* 下位互換 */ 追加日付名2: 'additional_date[1].label',
  /* 下位互換 */ 追加日付2: 'additional_date[1].date',
  /* 下位互換 */ 追加日付名3: 'additional_date[2].label',
  /* 下位互換 */ 追加日付3: 'additional_date[2].date',
}
/* eslint-enable prettier/prettier */
