import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('FileDropArea', {
    staticClass: "tw-bg-surface tw-rounded-lg tw-max-w-full tw-text-center tw-border !tw-border-dashed tw-p-6",
    attrs: {
      "dragging-class": "!tw-bg-background",
      "accept": "text/csv"
    },
    on: {
      "file": _vm.onFilePicked
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VIcon, {
          staticClass: "fa fa-plus",
          attrs: {
            "aria-hidden": "true"
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" "), _c('div', {
          staticClass: "drop"
        }, [_c('p', {
          staticClass: "text-body-1"
        }, [_vm._v(_vm._s(_vm.$t('contacts.import.dropHere')))]), _vm._v(" "), _c('button', _vm._g({
          staticClass: "tw-btn tw-btn-fill-primary !tw-inline-flex",
          attrs: {
            "type": "button"
          },
          domProps: {
            "textContent": _vm._s(_vm.$t('contacts.import.orSelect'))
          }
        }, on)), _vm._v(" "), _vm.fileName && _vm.contacts.length ? _c('div', {
          staticClass: "rounded color-background mt-4 pa-4"
        }, [_c('div', {
          staticClass: "text-body-1 primary-text--text"
        }, [_c(VIcon, {
          attrs: {
            "color": "primary-text"
          }
        }, [_vm._v("mdi-file-check-outline")]), _vm._v("\n          " + _vm._s(_vm.fileName) + "\n          "), _c('button', {
          staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info !tw-inline-flex tw-align-middle",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.removeFile
          }
        }, [_c('mdi-close', {
          staticClass: "tw-size-6"
        })], 1)], 1), _vm._v(" "), _c('div', {
          staticClass: "d-flex justify-center mt-4"
        }, [_c('div', {
          staticClass: "text-body-2",
          domProps: {
            "textContent": _vm._s(_vm.$t('contacts.import.loadedMessage', [_vm.values.length]))
          }
        }), _vm._v(" "), _c('TextButton', {
          staticClass: "ml-2",
          attrs: {
            "value": _vm.$t('contacts.import.check')
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('click:preview');
            }
          }
        })], 1)]) : _vm._e()])];
      }
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };