/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./EditContactDialog.vue?vue&type=template&id=0e1edafb"
import script from "./EditContactDialog.vue?vue&type=script&lang=js"
export * from "./EditContactDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TwRadio: require('/code/src/components/input/TwRadio.vue').default,Input: require('/code/src/components/input/Input.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,AlertArea: require('/code/src/components/AlertArea.vue').default,SmallLabel: require('/code/src/components/text/SmallLabel.vue').default,AddressList: require('/code/src/components/widgets/AddressList.vue').default,FormHighlight: require('/code/src/components/FormHighlight.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,AddressLabel: require('/code/src/components/widgets/AddressLabel.vue').default,CustomDialog: require('/code/src/components/dialog/CustomDialog.vue').default})
