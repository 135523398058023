import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import Intersect from 'vuetify/lib/directives/intersect';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomDialog', {
    attrs: {
      "form": "",
      "loading": _vm.loading.save,
      "title": _vm.contactId ? _vm.$t('action.editContact') : _vm.$t('action.addContact'),
      "negative": _vm.$t('action.cancel'),
      "positive": _vm.contactId ? _vm.$t('action.save') : _vm.$t('action.add'),
      "disabled-positive": !_vm.isSubmitEnable,
      "before-cancel": _vm.beforeCancel
    },
    on: {
      "click:positive": _vm.onClickSave,
      "open": _vm.resetForm
    },
    model: {
      value: _vm.display,
      callback: function callback($$v) {
        _vm.display = $$v;
      },
      expression: "display"
    }
  }, [_vm.linkTargetAccount ? _c('div', {
    staticClass: "tw-flex tw-flex-row tw-gap-4 tw-mt-4 tw-mb-8 tw-text-on-surface"
  }, [_c('TwRadio', {
    attrs: {
      "current": _vm.accountLinkMethod,
      "text": _vm.$t('action.doLinkContactToAccount'),
      "value": "select",
      "name": "method"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.accountLinkMethod = $event;
      }
    }
  }), _vm._v(" "), _c('TwRadio', {
    attrs: {
      "current": _vm.accountLinkMethod,
      "text": _vm.$t('action.doAddContactAndLinkAccount'),
      "value": "create",
      "name": "method"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.accountLinkMethod = $event;
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.linkTargetAccount && _vm.accountLinkMethod === 'select' ? [_c('Input', {
    attrs: {
      "initial-value": null,
      "type": "autocomplete",
      "items": _vm.accountLinkContacts,
      "item-text": function itemText(item) {
        return _vm.$fullName(item);
      },
      "label": _vm.$t('action.selectionContact'),
      "item-value": "id"
    },
    scopedSlots: _vm._u([_vm.loading.contacts ? {
      key: "append",
      fn: function fn() {
        return [_c(VProgressCircular, {
          attrs: {
            "size": "24",
            "color": "primary",
            "indeterminate": ""
          }
        })];
      },
      proxy: true
    } : null, _vm.accountLinkContactsTotal > _vm.accountLinkContacts.length ? {
      key: "append-item",
      fn: function fn() {
        return [_c('div', {
          directives: [{
            def: Intersect,
            name: "intersect",
            rawName: "v-intersect",
            value: _vm.fetchContacts,
            expression: "fetchContacts"
          }],
          staticClass: "d-flex justify-center"
        }, [_c(VProgressCircular, {
          attrs: {
            "size": "24",
            "color": "primary",
            "indeterminate": ""
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.accountLinkSelectedContactId,
      callback: function callback($$v) {
        _vm.accountLinkSelectedContactId = $$v;
      },
      expression: "accountLinkSelectedContactId"
    }
  })] : _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_vm.isEditDisabled ? _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('AlertArea', {
    staticClass: "mb-2",
    attrs: {
      "type": "info",
      "no-margin": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        var _vm$value, _vm$value2, _vm$value3, _vm$value4;
        return [_vm._v("\n          " + _vm._s(_vm.$t('warning.disabledEdit', [_vm.$t('contact')])) + "\n          "), (_vm$value = _vm.value) !== null && _vm$value !== void 0 && _vm$value.crm_id ? _c('div', {
          staticClass: "d-flex align-center"
        }, [(_vm$value2 = _vm.value) !== null && _vm$value2 !== void 0 && _vm$value2.get_crm_display ? _c('LinkText', {
          attrs: {
            "text": (_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.get_crm_display,
            "to": (_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.crm_url
          }
        }) : _vm._e(), _vm._v(" "), _c('span', {
          staticClass: "text-caption secondary-text--text ml-2"
        }, [_vm._v("\n              " + _vm._s(_vm.$t('lastSyncAt', [_vm.value.last_synced_at ? _vm.$datetime(_vm.value.last_synced_at) : _vm.$t('notSync')])) + "\n            ")])], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 3278461031)
  })], 1) : _vm._e(), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "5"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.last_name,
      "label": _vm.$t('lastName'),
      "required": "",
      "disabled": _vm.isEditDisabled
    },
    model: {
      value: _vm.form.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "last_name", $$v);
      },
      expression: "form.last_name"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "5"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.first_name,
      "label": _vm.$t('firstName'),
      "disabled": _vm.isEditDisabled
    },
    model: {
      value: _vm.form.first_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "first_name", $$v);
      },
      expression: "form.first_name"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "2"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.title,
      "label": _vm.$t('salutation'),
      "required": ""
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.email,
      "label": _vm.$t('email'),
      "rules": [function (v) {
        return _vm.$isValidEmailOrEmpty(v) || _vm.$t('error.email');
      }],
      "disabled": _vm.isEditDisabled
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.tel,
      "label": _vm.$t('phone'),
      "maxlength": "24",
      "disabled": _vm.isEditDisabled
    },
    model: {
      value: _vm.form.tel,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tel", $$v);
      },
      expression: "form.tel"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('FormHighlight', {
    attrs: {
      "value": _vm.form.shipping_addresses,
      "initial-value": _vm.initialFormData.shipping_addresses
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.form, "shipping_addresses", $event);
      }
    }
  }, [_c('SmallLabel', {
    staticClass: "mb-1",
    attrs: {
      "label": _vm.$t('address') + (_vm.form.shipping_addresses.length > 0 ? "(".concat(_vm.form.shipping_addresses.length, "/").concat(_vm.$t('case', [10]), ")") : '')
    }
  }), _vm._v(" "), _c('AddressList', {
    attrs: {
      "editable": "",
      "addresses": _vm.form.shipping_addresses,
      "synced": _vm.isEditDisabled
    },
    on: {
      "click:add": function clickAdd($event) {
        return _vm.$refs.addressDialog.showContact(_vm.contactId);
      },
      "click:edit": function clickEdit($event) {
        return _vm.$refs.addressDialog.showContact(_vm.contactId, $event);
      },
      "click:delete": _vm.onClickDeleteAddress
    }
  })], 1)], 1), _vm._v(" "), !_vm.linkTargetAccount ? _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.account_id,
      "type": "autocomplete",
      "label": _vm.$t('accountName'),
      "items": _vm.accounts,
      "item-text": "name",
      "item-value": "id",
      "disabled": _vm.isEditDisabled,
      "placeholder": _vm.$t('notSet')
    },
    on: {
      "change": _vm.fetchSelectedAccount
    },
    scopedSlots: _vm._u([_vm.loading.accounts || _vm.form.account_id ? {
      key: "append",
      fn: function fn() {
        return [_vm.loading.accounts ? _c(VProgressCircular, {
          attrs: {
            "size": "24",
            "color": "primary",
            "indeterminate": ""
          }
        }) : _vm._e(), _vm._v(" "), _vm.form.account_id ? _c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VDivider, {
          staticClass: "mx-2",
          attrs: {
            "vertical": ""
          }
        }), _vm._v(" "), _c('TextButton', {
          staticClass: "mr-1",
          attrs: {
            "value": _vm.$t('action.editAccount'),
            "icon": "mdi-pencil-outline"
          },
          on: {
            "mouseup": function mouseup($event) {
              $event.stopPropagation();
            },
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$refs.accountDialog.showAccount(_vm.selectedAccount);
            }
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    } : null, {
      key: "prepend-item",
      fn: function fn() {
        return [_c(VListItem, {
          attrs: {
            "ripple": ""
          },
          on: {
            "mousedown": function mousedown($event) {
              $event.preventDefault();
            },
            "click": function click($event) {
              return _vm.$refs.accountDialog.showAccount();
            }
          }
        }, [_c(VListItemAction, [_c(VIcon, [_vm._v("mdi-plus-circle")])], 1), _vm._v(" "), _c(VListItemContent, [_c(VListItemTitle, [_vm._v("\n                " + _vm._s(_vm.$t('action.addAccount')) + "\n              ")])], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.form.account_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "account_id", $$v);
      },
      expression: "form.account_id"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.department,
      "label": _vm.$t('department'),
      "disabled": _vm.isEditDisabled
    },
    model: {
      value: _vm.form.department,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "department", $$v);
      },
      expression: "form.department"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.job_title,
      "label": _vm.$t('jobTitle'),
      "disabled": _vm.isEditDisabled
    },
    model: {
      value: _vm.form.job_title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "job_title", $$v);
      },
      expression: "form.job_title"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.description,
      "label": _vm.$t('note'),
      "type": "multiLine",
      "rows": "3"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.primary_shipping_address_id,
      "required": _vm.selectableAddresses.length > 0,
      "label": _vm.$t('defaultAddressAsDestination'),
      "type": "select",
      "items": _vm.selectableAddresses,
      "item-text": "label",
      "item-value": "id",
      "disabled": !_vm.selectableAddresses.length,
      "placeholder": _vm.$t('notSet')
    },
    scopedSlots: _vm._u([_vm.loading.selectedAccount ? {
      key: "append",
      fn: function fn() {
        return [_c(VProgressCircular, {
          attrs: {
            "size": "24",
            "color": "primary",
            "indeterminate": ""
          }
        })];
      },
      proxy: true
    } : null, {
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('AddressLabel', {
          attrs: {
            "detail": "",
            "address": item
          }
        })];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item,
          attrs = _ref2.attrs;
        return [_c('AddressLabel', {
          attrs: {
            "detail": "",
            "address": item,
            "active-class": attrs.activeClass,
            "selected": _vm.form.primary_shipping_address_id === item.id
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.primary_shipping_address_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "primary_shipping_address_id", $$v);
      },
      expression: "form.primary_shipping_address_id"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('FormHighlight', {
    attrs: {
      "value": _vm.form.delivery_block,
      "initial-value": _vm.initialFormData.delivery_block
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.form, "delivery_block", $event);
      }
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.$t('action.blockDelivery'),
      "color": "primary",
      "hide-details": ""
    },
    model: {
      value: _vm.form.delivery_block,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "delivery_block", $$v);
      },
      expression: "form.delivery_block"
    }
  })], 1)], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.owner_email,
      "type": "autocomplete",
      "items": _vm.currentTeam.users,
      "item-text": "name",
      "item-value": "email",
      "required": "",
      "hide-details": "",
      "label": _vm.$t('owner')
    },
    scopedSlots: _vm._u([_vm.loading.team ? {
      key: "append",
      fn: function fn() {
        return [_c(VProgressCircular, {
          attrs: {
            "size": "24",
            "color": "primary",
            "indeterminate": ""
          }
        })];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.form.owner_email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "owner_email", $$v);
      },
      expression: "form.owner_email"
    }
  })], 1)], 1), _vm._v(" "), _c('EditAddressDialog', {
    ref: "addressDialog",
    attrs: {
      "commit": false
    },
    on: {
      "change": _vm.onChangeAddress,
      "create": _vm.onCreateAddress
    }
  }), _vm._v(" "), _c('EditAccountDialog', {
    ref: "accountDialog",
    on: {
      "change": _vm.onChangeAccount,
      "create": _vm.onAddAccount
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };