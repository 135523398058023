import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _setup.$t('contacts.import.contactImport'),
      "actions": {
        false: _setup.$t('action.cancel'),
        true: _setup.$t('action.import')
      },
      "valid": _setup.isValid,
      "loading": _setup.loading,
      "before-close": _setup.beforeClose,
      "persistent": "",
      "width": "600px"
    },
    on: {
      "click": _setup.onClick,
      "close": function close($event) {
        return _setup.emits('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "title.append",
      fn: function fn() {
        return [_c('div', {
          attrs: {
            "tabindex": "0"
          }
        }), _vm._v(" "), _c('IconButton', {
          attrs: {
            "x-small": "",
            "tooltip": _setup.$t('contacts.import.aboutContactImport'),
            "icon": "mdi-help-circle"
          },
          on: {
            "click": _setup.$openAboutContactImport
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c(VCardText, {
    staticClass: "py-2"
  }, [_c('SectionSubTitle', {
    staticClass: "primary-text--text",
    attrs: {
      "number": "1"
    }
  }, [_vm._v("\n      " + _vm._s(_setup.$t('contacts.import.prepareCvs')) + "\n    ")]), _vm._v(" "), _c(VRow, {
    staticClass: "mt-4"
  }, [_c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_c(VCard, {
    staticClass: "pa-4 text-center",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "href": "/template/contacts.csv",
      "download": "",
      "target": "_blank"
    }
  }, [_c(VIcon, {
    attrs: {
      "color": "primary",
      "size": "40"
    }
  }, [_vm._v("mdi-table-edit")]), _vm._v(" "), _c('div', {
    staticClass: "primary--text text-subtitle-2"
  }, [_vm._v("\n            " + _vm._s(_setup.$t('contacts.import.defaultTemplate')) + "\n          ")])], 1)], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_c(VCard, {
    staticClass: "pa-4 text-center",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "href": "/template/crm-contacts.csv",
      "download": "",
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row h-10 justify-center"
  }, [_c(_setup.SalesforceSVG, {
    attrs: {
      "width": "40px",
      "height": "40px"
    }
  }), _vm._v(" "), _c(_setup.HubspotSVG, {
    attrs: {
      "width": "40px",
      "height": "40px"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "primary--text text-subtitle-2"
  }, [_vm._v("\n            " + _vm._s(_setup.$t('contacts.import.crmIntegrationTemplate')) + "\n          ")])])], 1)], 1), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "primary-text--text",
    attrs: {
      "number": "2"
    }
  }, [_vm._v("\n      " + _vm._s(_setup.$t('contacts.import.selectCvs')) + "\n    ")]), _vm._v(" "), _c(_setup.ContactCsvLoader, {
    ref: "loader",
    staticClass: "mt-4",
    attrs: {
      "rowheaders": _setup.rowheaders,
      "headers": _setup.headers,
      "values": _setup.values
    },
    on: {
      "update:rowheaders": function updateRowheaders($event) {
        _setup.rowheaders = $event;
      },
      "update:headers": function updateHeaders($event) {
        _setup.headers = $event;
      },
      "update:values": function updateValues($event) {
        _setup.values = $event;
      },
      "click:preview": function clickPreview($event) {
        return _setup.$showDialog(_setup.ImportContactPreviewDialog, {
          rowheaders: _setup.rowheaders,
          headers: _setup.headers,
          values: _setup.values
        });
      }
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "primary-text--text",
    attrs: {
      "number": "3"
    }
  }, [_vm._v("\n      " + _vm._s(_setup.$t('contacts.import.selectDefaultAddressAsDestination')) + "\n      "), _c('FloatingTooltip', {
    attrs: {
      "right": "",
      "max-width": "300px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VIcon, _vm._g(_vm._b({
          staticClass: "ml-1",
          attrs: {
            "small": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v("\n            mdi-information\n          ")])];
      }
    }])
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v("\n          " + _vm._s(_setup.$t('defaultAddressNotice')) + "\n        ")])])], 1), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-flex-row tw-gap-4 tw-mt-4 tw-text-on-surface"
  }, [_c('TwRadio', {
    attrs: {
      "current": _setup.selectedDefaultAddress,
      "text": _setup.$t('account'),
      "value": "account",
      "name": "type"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _setup.selectedDefaultAddress = $event;
      }
    }
  }), _vm._v(" "), _c('TwRadio', {
    attrs: {
      "current": _setup.selectedDefaultAddress,
      "text": _setup.$t('contact'),
      "value": "contact",
      "name": "type"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _setup.selectedDefaultAddress = $event;
      }
    }
  })], 1), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "primary-text--text",
    attrs: {
      "number": "4"
    }
  }, [_vm._v("\n      " + _vm._s(_setup.$t('contacts.import.addTagToImportedContact')) + "\n    ")]), _vm._v(" "), _c('TagSelect', {
    staticClass: "mt-4",
    attrs: {
      "value": _setup.selectedTags,
      "items": _setup.tags
    },
    on: {
      "update:value": function updateValue($event) {
        _setup.selectedTags = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };