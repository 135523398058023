var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _setup.$t('contacts.import.check'),
      "actions": {},
      "width": "100%"
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click', $event);
      },
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_c('div', {
    staticClass: "import-preview"
  }, [_c('StyledCellTable', {
    attrs: {
      "dense": "",
      "headers": _setup.tableHeaders,
      "items": _setup.tableValues,
      "items-per-page": -1,
      "footer-props": {
        'items-per-page-options': [10, 25, 50, 100, -1]
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };